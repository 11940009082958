@import "../../global.scss";

.portfolio {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    h1 {
      font-size: 50px;

      @include mobile{
          font-size: 20px;
      }
    }
    span{
      width: 200px;
      height: 2px;
      background-color: #15023a;

      @include mobile{
        width: 100px;
      }
    }
  
    ul {
      margin: 10px;
      padding: 0;
      list-style: none;
      display: flex;
  
      @include mobile{
        margin: 10px 0;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  
    .container {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
  
      @include mobile{
        width: 100%;
      }
  
      .item {
        width: 220px;
        height: 150px;
        border-radius: 20px;
        border: 1px solid rgb(240, 239, 239);
        margin: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        transition: all .5s ease;
        cursor: pointer;
  
        @include mobile{
          width: 130px;
          height: 100px;
        }
  
        h3 {
          position: absolute;
          font-size: 20px;
        }
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }
  
        &:hover {
          background-color: $mainColor;
          img {
            opacity: 0.2;
            z-index: 0;
          }
        }
      }
    }
  }

// .portfolio{
//     background-color: rgb(255, 255, 255);
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     h1{
//         font-size: 50px;
//     }

//     ul{
//         margin: 10px;
//         padding: 0;
//         list-style: none;
//         display: flex;


//     .container{
//         width: 70%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-wrap: wrap;

//         .item{
//             width: 220px;
//             height: 150px;
//             border-radius: 20px;
//             border: 1px solid rgb(240, 239, 239);
//             margin: 10px 20px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             color: white;
//             position: relative;
//             transition: all .5s ease;
//             cursor: pointer;

//             h3{
//                 position: absolute;
//                 font-size: 20px;
//             }

//             img{
//                 width: 100%;
//                 height: 100%;
//                 object-fit: cover;
//                 z-index: 1;
//             }

//             &:hover{
//                 background-color: $mainColor;
//                 img{
//                     opacity: 0.2;
//                     z-index: 0;
//                 }
//             }
//         }
//     }
// } 
// }