@import "../../global.scss";

.intro{
    background-color: rgb(255, 255, 255);
    display: flex;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }

    .left{
        flex: 0.5;
        overflow: hidden;


        .imgContainer{
            width: 700px;
            // height: 510px;
            height: 700px;
            overflow: hidden;
            background-color: crimson;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            float: right;

            @include mobile {
                align-items: flex-start;
            }
            

            .img{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;

            

            }
            img{
                width: 80%;

                @include mobile{
                    height: 50%;
                }
            }
        }
    }

    .right{
        flex: 0.5;
        position: relative;

        .wrapper{
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile{
                padding-left: 0;
                align-items: center;
            }

            h1{
                font-size: 60px;
                margin: 10px 0;

                @include mobile{
                    font-size: 40px;

                }
            }
            h2{
                font-size: 35px;

            }
            h3{
                font-size: 30px;

                @include mobile{
                font-size: 20px;
                }

                span{
                    font-size: inherit;
                    color: crimson;
                }

                .ityped-cursor{
                    animation: blink 1s infinite;
                }

                @keyframes blink {
                    50%{
                        opacity: 1;
                    }
                    100%{
                        opacity: 0;
                    }
                }
            }
        }
        a{
            position: absolute;
            bottom: 30px;
            left: 40%;
            transform-origin: 50% 50%;
            animation: jump .7s linear alternate infinite;

            @keyframes jump {
                0%   {transform: translate3d(0,0,0) scale3d(1,1,1);}
                40%  {transform: translate3d(0,30%,0) scale3d(.7,1.5,1);}
                100% {transform: translate3d(0,100%,0) scale3d(1.5,.7,1);}
              }
            

            img{
                width: 30px;
            }
        }
    }
} 