@import "../../global.scss";

.testimonials{
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    flex-direction: column;

    @include mobile {
     justify-content: space-around;             
    }

    h1{
        font-size: 50px;

        @include mobile {
          font-size: 20px;
        }
    }
    span{
        width: 300px;
        height: 2px;
        background-color: crimson;

        @include mobile {
          width: 120px;
        }
    }
    .container {
        width: 100%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        @include mobile {
         flex-direction: column;
         height: 90%;
        }
    
        .card {
          width: 290px;
          height: 90%;
          border-radius: 10px;
          box-shadow: 0px 0px 15px -8px black;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          flex-wrap: wrap;
          padding: 20px;
          transition: all 1s ease;

          @include mobile {
            height: 40%;
            width: 80%;
          } 
    
          &.featured{
              width: 310px;
              height: 75%;
              margin: 0 30px;
              border-width: 4px;
              border-style: solid;
              border-image: linear-gradient(to right, crimson, rgb(161, 15, 44), rgb(224, 122, 142)) 1;
              box-shadow: 0 0 5px crimson, 0 0 10px white, 0 0 2px black;

              @include mobile {
                margin: 10px;
                height: 35%;
                width: 80%;
                flex-wrap: nowrap;
                padding: 10px;
              }
          }
    
          &:hover{
              transform: scale(1.1);
          }
    
          .top {
            display: flex;
            align-items: center;
            justify-content: center;
    
            img {
              &.left,
              &.right {
                height: 25px;
              }
              &.user {
                height: 60px;
                width: 60px;
                border-radius: 50%;
                object-fit: cover;
                margin: 0 30px;

                @include mobile {
                  width: 40px;
                  height: 40px;
                }
              }
            }
          }
    
          .center {
            padding: 10px;
            border-radius: 10px;
            background-color: rgb(250, 244, 245);
            

            @include mobile {
             font-size: 12px;
             padding: 5px;
            }
          }
          .bottom {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
    
            h3 {
              margin-bottom: 5px;
              

              @include mobile {
               font-size: 14px;
              }
            }
            h4{
                color: rgb(121, 115, 115);
              
                @include mobile {
                 font-size: 13px;
                }
            }
          }
        }
      }
}
