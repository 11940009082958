@import "../../global.scss";

.contact {
    background-color: white;
    display: flex;

    @include mobile {
        flex-direction: column;
    }

    .left {
        flex: 1;
        overflow: hidden;

        img {
            height: 100%;
        }
    }

    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
            font-size: 30px;
            margin-bottom: 20px;
        }

        form {
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            input {
                width: 300px;
                height: 60px;
                font-size: 14px;
                border: none;
                border-radius: 15px;
                padding: 15px;
                background-color: #e8e8e8;
                box-shadow: 6px 6px 12px #ffffff,
                    -6px -6px 12px #c5c5c5;
                font-weight: bold;
                // max-width: 200px;

                &:focus {
                    outline: none;
                }


                @include mobile {
                    width: 200px;
                    height: 20px;
                }
            }

            textarea {
                width: 300px;
                height: 200px;
                font-size: 14px;
                border: none;
                border-radius: 15px;
                padding: 15px;
                background-color: #e8e8e8;
                box-shadow: 6px 6px 12px #ffffff,
                    -6px -6px 12px #c5c5c5;
                font-weight: bold;

                &:focus {
                    outline: none;
                }

                @include mobile {
                    width: 200px;
                    height: 100px;
                }
            }

            button {
                width: 200px;
                height: 40px;
                color: white;
                background-color: crimson;
                border: none;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;
                box-shadow: 0 0 10px crimson;
                margin-bottom: 5px;


                &:focus {
                    outline: none;
                }
            }

            span {
                color: green;
            }
        }
    }
}